import React, { ReactNode } from "react"

import Header from "./Header"
import Footer from "./Footer"
import Head from "./Head"

import "../css/normalize.css"
import "./layout.css"

type Props = {
  children: ReactNode
  className?: string
  hideEmbellishment?: boolean
}

function Layout({
  children,
  className = "",
  hideEmbellishment = false,
}: Props) {
  return (
    <div className={className}>
      <Head />
      <Header hideEmbellishment={hideEmbellishment} />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
